import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { environment } from 'src/environments/environment';
import { ApiResponseCacheService } from '../../cache/api-response-cache/api-response-cache.service';
import { WidgetCacheService } from '../../cache/widget-cache/widget-cache.service';
import { IotService } from '../../iot/iot.service';
import { HttpService } from '../http-main/http.service';
import { IApiInfo } from './../../../interfaces/api/IApiInfo';

@Injectable({
    providedIn: 'root'
})
export class ListHttpService extends HttpService {
    timezone: string;
    constructor(
        http: HttpClient,
        public widgetCacheService: WidgetCacheService,
        iotService: IotService,
        apiResponseCacheService: ApiResponseCacheService,
        ngZone: NgZone
    ) {
        super(http, iotService, apiResponseCacheService, ngZone);
    }

    listViews(resolve, reject) {
        const apiArgs: IHitApi = {
            url: ApiUrls.VIEW_ENDPOINT,
            input: {},
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (response) => {
                resolve(response);
            },
            errorFunction: (error) => {
                reject(error);
            }
        };

        new HitApi(apiArgs, this, this.ngZone).hitApi();
    }
    listViewsHeader(resolve, reject) {
        const apiArgs: IHitApi = {
            url: ApiUrls.VIEW_ENDPOINT_HEADER,
            input: {},
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (response) => {
                resolve(response);
            },
            errorFunction: (error) => {
                reject(error);
            }
        };

        new HitApi(apiArgs, this, this.ngZone).hitApi();
    }

    listWidgetsHeader(resolve, reject) {
        const apiArgs: IHitApi = {
            url: ApiUrls.GET_WIDGET_CATALOG,
            input: {},
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (response) => {
                resolve(response);
            },
            errorFunction: (error) => {
                reject(error);
            }
        };

        new HitApi(apiArgs, this, this.ngZone).hitApi();
    }

    get listViewsApiInfo(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.VIEW_ENDPOINT,
            host: '',
            requestType: RequestType.GET,
            authorization: AuthorizationType.BEARER_TOKEN,
            customInput: null
        };
    }
    listAclDependentViewsApiInfo(replaceValue): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.ACL_DEPENDENT_VIEW_ENDPOINT.replace(
                '{aclId}',
                replaceValue
            ),
            host: '',
            requestType: RequestType.GET,
            authorization: AuthorizationType.BEARER_TOKEN,
            customInput: null,
            intactUrl: ApiUrls.ACL_DEPENDENT_VIEW_ENDPOINT
        };
    }
    get userListViewsApiInfo(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.TENANT_VIEW_ENDPOINT,
            host: '',
            requestType: RequestType.GET,
            authorization: AuthorizationType.BEARER_TOKEN,
            customInput: null
        };
    }
    get tenancyTypeApiInfo(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.TENANCY_TYPE,
            host: '',
            requestType: RequestType.GET,
            authorization: AuthorizationType.BEARER_TOKEN,
            customInput: null
        };
    }
    get timeZoneApiInfo(): IApiInfo {
        if (
            this.DEFAULT_BASE_URL.includes(
                'https://apistaging.centilytics.com/v2'
            ) ||
            this.DEFAULT_BASE_URL.includes(
                'https://api-preprod.centilytics.com'
            )
        ) {
            this.timezone = 'stage';
        } else if (
            this.DEFAULT_BASE_URL.includes('https://api.centilytics.com/v2')
        ) {
            this.timezone = 'prod';
        } else {
            this.timezone = 'dev';
        }
        return {
            apiRouteSuffix: ApiUrls.TIMEZONE_ID,
            host: `https://api-${this.timezone}-v1.centilytics.com`,
            requestType: RequestType.POST,
            authorization: AuthorizationType.AUTHORIZATION_TOKEN_V2
        };
    }

    get newTimeZoneApi(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.NEW_TIMEZONE_API,
            host: environment.baseUrl,
            requestType: RequestType.POST,
            authorization: AuthorizationType.NOT_AUTHORIZED
        };
    }

    get priceTypeApiInfo(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.PRICE_TYPE_LIST,
            host: '',
            requestType: RequestType.GET,
            authorization: AuthorizationType.BEARER_TOKEN,
            customInput: null
        };
    }
    get assessmentCustomerListInfo(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.ASSESSMENT_CUSTOMER_LIST,
            host: '',
            requestType: RequestType.GET,
            authorization: AuthorizationType.BEARER_TOKEN,
            customInput: null
        };
    }
    get createUserAssessmentListInfo(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.ASSESSE_ASSESSMENT_LIST,
            host: '',
            requestType: RequestType.POST,
            authorization: AuthorizationType.BEARER_TOKEN,
            customInput: null
        };
    }
    get snsExistingAccountApiInfo(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.SNS_EXISTING_ACCOUNT,
            host: '',
            requestType: RequestType.GET,
            authorization: AuthorizationType.BEARER_TOKEN,
            customInput: null
        };
    }
    get snsNewAccountApiInfo(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.SNS_NEW_ACCOUNT,
            host: '',
            requestType: RequestType.GET,
            authorization: AuthorizationType.BEARER_TOKEN,
            customInput: null
        };
    }

    get snsListingApiInfo(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.SNS_LISTING,
            host: '',
            requestType: RequestType.GET,
            authorization: AuthorizationType.BEARER_TOKEN,
            customInput: null
        };
    }
    get awsAccountIdApiInfo(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.AWS_ACCOUNT_IDS,
            host: '',
            requestType: RequestType.POST,
            authorization: AuthorizationType.AUTHORIZATION_TOKEN_V2,
            customInput: null
        };
    }
    get awsRegionIdApiInfo(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.AWS_REGION_IDS,
            host: '',
            requestType: RequestType.POST,
            authorization: AuthorizationType.AUTHORIZATION_TOKEN_V2,
            customInput: null
        };
    }
    get awsInstanceTypeApiInfo(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.AWS_INSTANCE_TYPE,
            host: '',
            requestType: RequestType.POST,
            authorization: AuthorizationType.AUTHORIZATION_TOKEN_V2,
            customInput: null
        };
    }
    get cacRegionsApiInfo(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.CAC_REGIONS_LIST,
            host: '',
            requestType: RequestType.GET,
            authorization: AuthorizationType.BEARER_TOKEN,
            customInput: null
        };
    }

    listAcls(resolve, reject) {
        const args = Helper.generateHitApiConfig(this.listAclApiInfo);
        args.function = (response) => {
            resolve(response);
        };
        args.errorFunction = (error) => {
            reject(error);
        };

        new HitApi(args, this, this.ngZone).hitApi();
    }
    get listAclApiInfo(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.ACL_LISTING,
            host: environment.baseUrl,
            requestType: RequestType.GET,
            authorization: AuthorizationType.BEARER_TOKEN,
            customInput: null
        };
    }
    get listAclApiInfoWithInput(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.ACL_LISTING,
            host: '',
            requestType: RequestType.GET,
            authorization: AuthorizationType.BEARER_TOKEN,
            customInput: {}
        };
    }

    listTenantViews(resolve, reject) {
        const apiArgs: IHitApi = {
            url: ApiUrls.TENANT_VIEW_ENDPOINT,
            input: {},
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (response) => {
                resolve(response);
            },
            errorFunction: (error) => {
                reject(error);
            }
        };

        new HitApi(apiArgs, this, this.ngZone).hitApi();
    }

    get azureCspAccountIds(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.CSP_ACCOUNT_IDS_LIST,
            host: '',
            requestType: RequestType.GET,
            authorization: AuthorizationType.BEARER_TOKEN,
            customInput: null
        };
    }
    get azureEaAccountIds(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.EA_ACCOUNT_IDS_LIST,
            host: '',
            requestType: RequestType.GET,
            authorization: AuthorizationType.BEARER_TOKEN,
            customInput: null
        };
    }
    get azurePlanAccountIds(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.PLAN_ACCOUNT_IDS_LIST,
            host: '',
            requestType: RequestType.GET,
            authorization: AuthorizationType.BEARER_TOKEN,
            customInput: null
        };
    }
    get azureMcaAccountIds(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.MCA_ACCOUNT_IDS_LIST,
            host: '',
            requestType: RequestType.GET,
            authorization: AuthorizationType.BEARER_TOKEN,
            customInput: null
        };
    }
    get awsAccountIds(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.AWS_ACCOUNT_IDS_LIST,
            host: '',
            requestType: RequestType.GET,
            authorization: AuthorizationType.BEARER_TOKEN,
            customInput: null
        };
    }
    get sponsorshipAccountIds(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.AZURE_SPONSORSHIP_ACCOUNT_IDS_LIST,
            host: '',
            requestType: RequestType.GET,
            authorization: AuthorizationType.BEARER_TOKEN,
            customInput: null
        };
    }
    get azurePayAsYouGoAccountIds(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.AZURE_PAY_AS_YOU_GO_ACCOUNT_IDS_LIST,
            host: '',
            requestType: RequestType.GET,
            authorization: AuthorizationType.BEARER_TOKEN,
            customInput: null
        };
    }

    get awsShareableAccountIds(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.AWS_SHAREABLE_ACCOUNT_IDS_LIST,
            host: '',
            requestType: RequestType.GET,
            authorization: AuthorizationType.BEARER_TOKEN,
            customInput: {}
        };
    }

    get azureCspShareableAccountIds(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.CSP_SHAREABLE_ACCOUNT_IDS_LIST,
            host: '',
            requestType: RequestType.GET,
            authorization: AuthorizationType.BEARER_TOKEN,
            customInput: {}
        };
    }
    get azureEaShareableAccountIds(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.EA_SHAREABLE_ACCOUNT_IDS_LIST,
            host: '',
            requestType: RequestType.GET,
            authorization: AuthorizationType.BEARER_TOKEN,
            customInput: {}
        };
    }
    get azurePlanShareableAccountIds(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.PLAN_SHAREABLE_ACCOUNT_IDS_LIST,
            host: '',
            requestType: RequestType.GET,
            authorization: AuthorizationType.BEARER_TOKEN,
            customInput: {}
        };
    }
    get azureMcaShareableAccountIds(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.MCA_SHAREABLE_ACCOUNT_IDS_LIST,
            host: '',
            requestType: RequestType.GET,
            authorization: AuthorizationType.BEARER_TOKEN,
            customInput: {}
        };
    }

    get autoTaskClientIds(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.AUTOTASK_CLIENT_IDS,
            host: '',
            customInput: {},
            requestType: RequestType.GET,
            authorization: AuthorizationType.BEARER_TOKEN
        };
    }
    get chargeType(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.CHARGE_TYPE,
            host: '',
            customInput: {},
            requestType: RequestType.GET,
            authorization: AuthorizationType.BEARER_TOKEN
        };
    }
    get awsAccount(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.LIST_GROUP_ACCOUNT_IDS,
            host: '',
            customInput: {},
            requestType: RequestType.GET,
            authorization: AuthorizationType.BEARER_TOKEN
        };
    }
    get payerAccountIds(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.PAYER_ACCOUNT_IDS,
            host: '',
            customInput: {},
            requestType: RequestType.POST,
            authorization: AuthorizationType.AUTHORIZATION_TOKEN_V2
        };
    }

    listConfigurations(replaceValue): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.INTEGRATION_STATUS_V2.replace(
                '{integration-type}',
                replaceValue
            ),
            host: '',
            requestType: RequestType.GET,
            authorization: AuthorizationType.BEARER_TOKEN,
            customInput: null,
            intactUrl: ApiUrls.INTEGRATION_STATUS_V2
        };
    }

    listFreshserviceChildCustomers(replaceValue): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.FRESHSERVICE_CHILD_CUSTOMERS.replace(
                '{configuration-name}/{action}',
                replaceValue
            ),
            host: '',
            requestType: RequestType.GET,
            authorization: AuthorizationType.BEARER_TOKEN,
            customInput: null,
            intactUrl: ApiUrls.FRESHSERVICE_CHILD_CUSTOMERS
        };
    }

    listArmJson(cloud): IApiInfo {
        return {
            apiRouteSuffix: `/${cloud}/provisioning/auth/stacktemplate/list-arm-json-templates`,
            host: '',
            requestType: RequestType.POST,
            authorization: AuthorizationType.AUTHORIZATION_TOKEN_V2,
            customInput: null
        };
    }
    listZendeskForm(widgetId): IApiInfo {
        return {
            apiRouteSuffix: Helper.replaceAll(
                ApiUrls.ZENDESK_CONFIG_FORM,
                '{widgetId}',
                widgetId
            ),
            host: '',
            requestType: RequestType.POST,
            authorization: AuthorizationType.BEARER_TOKEN,
            customInput: {},
            intactUrl: ApiUrls.ZENDESK_CONFIG_FORM
        };
    }

    // Salesforce
    listSalesforceForm(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.SALESFORCE_INTEGRATION_STATUS_CHECK,
            host: '',
            requestType: RequestType.POST,
            authorization: AuthorizationType.BEARER_TOKEN,
            customInput: {}
        };
    }
    get userListApi(): IApiInfo {
        return {
            apiRouteSuffix: `/identity-access-management/users/filter/user-name/assessment`,
            host: '',
            requestType: RequestType.POST,
            authorization: AuthorizationType.BEARER_TOKEN,
            customInput: null
        };
    }
    get companyListApi(): IApiInfo {
        return {
            apiRouteSuffix: `/view-and-api-management/assessment/audit-names/Hp`,
            host: '',
            requestType: RequestType.GET,
            authorization: AuthorizationType.NOT_AUTHORIZED,
            customInput: null,
            skipByPassRegion: true
        };
    }
    get preferredLanguagesApi(): IApiInfo {
        return {
            apiRouteSuffix: `/view-and-api-management/assessment/list-languages`,
            host: '',
            requestType: RequestType.GET,
            authorization: AuthorizationType.NOT_AUTHORIZED,
            customInput: null,
            skipByPassRegion: true
        };
    }
    get pmoListApi(): IApiInfo {
        return {
            apiRouteSuffix: `/identity-access-management/users/filter/user-name/assessment/pmo`,
            host: '',
            requestType: RequestType.POST,
            authorization: AuthorizationType.BEARER_TOKEN,
            customInput: null
        };
    }

    get getPresignedUrlForWidgetDocumentation(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.WIDGET_DOCUMENTATION_PRESIGNED_URL,
            host: '',
            requestType: RequestType.POST,
            authorization: AuthorizationType.BEARER_TOKEN
        };
    }

    get deleteMediaFileForWidgetDocumentation(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.WIDGET_DOCUMENTATION_REMOVE_MEDIA_FILE,
            host: '',
            requestType: RequestType.DELETE,
            authorization: AuthorizationType.BEARER_TOKEN
        };
    }

    get createWidgetDocumentation(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.WIDGET_DOCUMENTATION_CREATE,
            host: '',
            requestType: RequestType.POST,
            authorization: AuthorizationType.BEARER_TOKEN
        };
    }
    get saveAsDraftWidgetDocumentation(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.WIDGET_DOCUMENTATION_SAVE_AS_DRAFT,
            host: '',
            requestType: RequestType.POST,
            authorization: AuthorizationType.BEARER_TOKEN
        };
    }

    get downloadReportApi(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.DOWNLOAD_REPORT,
            authorization: AuthorizationType.BEARER_TOKEN,
            host: '',
            requestType: RequestType.POST
        };
    }

    getExisitingCompany(widgetId: string): IApiInfo {
        return {
            apiRouteSuffix: `/view-and-api-management/assessment/company-names/${widgetId}`,
            host: '',
            requestType: RequestType.POST,
            authorization: AuthorizationType.BEARER_TOKEN,
            customInput: null
        };
    }

    get timeRange(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.TIME_RANGE,
            authorization: AuthorizationType.BEARER_TOKEN,
            host: '',
            requestType: RequestType.GET
        };
    }

    get listCountries(): IApiInfo {
        return {
            apiRouteSuffix: `/view-and-api-management/assessment/list-countries`,
            host: '',
            requestType: RequestType.GET,
            authorization: AuthorizationType.NOT_AUTHORIZED,
            customInput: null,
            skipByPassRegion: true
        };
    }

    get emailTemplates(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.GET_EMAIL_TEMPLATES,
            authorization: AuthorizationType.BEARER_TOKEN,
            host: environment.baseUrl,
            requestType: RequestType.GET
        };
    }
    get emailTriggerPoints(): IApiInfo {
        return {
            apiRouteSuffix: ApiUrls.GET_EMAIL_CONFIG_TRIGGER_POINTS,
            authorization: AuthorizationType.BEARER_TOKEN,
            host: environment.baseUrl,
            requestType: RequestType.GET
        };
    }
}
