import { Component, NgZone, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Messages } from 'src/app/shared/classes/Messages';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import {
    DateTimeField,
    DropdownMultipleFieldAppearance,
    DropdownSingleFieldAppearance,
    NumberField,
    TextAreaField,
    TextFieldAppearance,
} from 'src/app/shared/enums/AppearanceType';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { UpdateAction } from 'src/app/shared/enums/UpdateAction';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormField } from 'src/app/shared/interfaces/form-generator/IFormField';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IButtonData } from 'src/app/shared/interfaces/table-generator/IButtonData';
import { IUpdateAction } from 'src/app/shared/interfaces/update-action/IUpdateAction';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { environment } from 'src/environments/environment';
import { CustomRequestWidgetFormValueKeys } from '../../../widgets/1.0/custom-request-widget/custom-request-widget.component';

@Component({
    selector: 'app-request-form-modal',
    templateUrl: './request-form-modal.component.html',
    styleUrls: ['./request-form-modal.component.sass']
})
export class RequestFormModalComponent implements OnInit {
    widgetRef: Widget;
    FIRST_LABEL;
    SECOND_LABEL;
    requestFormGenInput: IFormGeneratorInput = null;
    requestFormGroupRef: FormGroup = null;
    updateControl: BehaviorSubject<IUpdateAction>;
    value: string = undefined;
    widgetId;
    staticValueMap;
    enterDetailButton!: IButtonGeneratorInput;
    private isEdit: boolean = false;

    constructor(
        private modalInputData: ModalInjectedData,
        private modalService: ModalService,
        private httpService: HttpService,
        private ngZone: NgZone
    ) {
        this.widgetRef = modalInputData.data['widgetRef'];
        this.enterDetailButton = modalInputData.data['enterDetailsButton'];
        this.isEdit = !!modalInputData.data?.['isEdit'] || false;
        this.widgetId = this.widgetRef.widgetData.widgetId;
    }

    ngOnInit(): void {
        let url = `https://configurations.centilytics.com/{region}/assessment-jsons/${this.widgetId}.json`
        if (environment?.baseUrl?.includes('api.centilytics.com')) {
            url = url.replace('{region}', '1');
        } else if (environment?.baseUrl?.includes('apistaging.centilytics.com')){
            url = url.replace('{region}', '4');
        } else {
            url = url.replace('{region}', '1');
        }
        new HitApi(
            {
                url: url,
                requestType: RequestType.GET,
                uniqueIdentity: Symbol(),
                input: {},
                config: {
                    authorization: AuthorizationType.NOT_AUTHORIZED,
                    ignoreBaseUrl: true
                },
                function: (res) => {
                    this.staticValueMap = res.list;
                    this.FIRST_LABEL = res.firstLabel;
                    this.SECOND_LABEL = res.secondLabel;
                    this.initialized();
                },
                errorFunction: (err) => {
                    this.initialized();
                }
            },
            this.httpService,
            this.ngZone
        ).hitApi();
    }

    initialized() {
        const form = this.widgetRef.widgetData.widgetInfo.form;

        const fields: IFormField[] = [];

        if (form && form.fields && form.fields.length) {
            form.fields.forEach((each) => {
                const eachField: IFormField = {
                    label: each.label,
                    name: each.id,
                    fieldType: each.fieldType,
                    required: each.required,
                    appearance: this.getAppearanceForFieldType(each.fieldType),
                    placeholder: each.placeHolder || '',
                    validations: each.required
                        ? [
                              {
                                  validator: CustomValidators.required,
                                  errorMessage: ''
                              }
                          ]
                        : [],
                    value: this.isEdit
                        ? this.getFieldValueInEditMode(each)
                        : (each.text || ''),
                    listData:
                        this.staticValueMap &&
                        this.FIRST_LABEL &&
                        this.SECOND_LABEL &&
                        this.SECOND_LABEL === each.label
                            ? []
                            : each.listData
                            ? each.listData
                            : [],
                    showLabelInDropdownLegend:
                        each.fieldType === FilterType.DROPDOWN_SINGLE ||
                        each.fieldType === FilterType.DROPDOWN_MULTIPLE
                            ? true
                            : false
                };

                fields.push(eachField);
            });
        }
        this.requestFormGenInput = {
            submitButton: {
                buttonName: 'Submit',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.FLAT,
                function: (buttonRef: IButtonData) => {
                    this.prepareDataForApi(buttonRef);
                },
                showLoader: true
            },
            formName: form.formName,
            state: FormState.CREATE,
            fields: fields
        };
    }

    private getAppearanceForFieldType(fieldType: FilterType) {
        switch (fieldType) {
            case FilterType.DROPDOWN_SINGLE:
                return DropdownSingleFieldAppearance.TYPE_7;
            case FilterType.TEXTAREA:
                return TextAreaField.TYPE_7;
            case FilterType.NUMBER:
                return NumberField.TYPE_7;
            case FilterType.DATE_TIME:
                return DateTimeField.TYPE_7;
            case FilterType.DROPDOWN_MULTIPLE:
                return DropdownMultipleFieldAppearance.TYPE_7;
            case FilterType.SHORT_TEXT:
                return TextFieldAppearance.TYPE_7;
            default:
                return undefined;
        }
    }

    private getFieldValueInEditMode(field) {
        const formPreSavedData = this.modalInputData.data.formPreSavedData;
        const formSavedRowList = formPreSavedData?.['Form Details'];
        const formSavedRowObj = formSavedRowList.find((f: any) => f.id === field.id);
        const fieldType: FilterType = field?.fieldType;
        switch (fieldType) {
            case FilterType.DROPDOWN_SINGLE:
            case FilterType.TEXTAREA:
            case FilterType.SHORT_TEXT:
            case FilterType.NUMBER:
                return formSavedRowObj[CustomRequestWidgetFormValueKeys.INPUTSTRING];
            case FilterType.DATE_TIME:
                const numericDateEpoch = Number(formSavedRowObj[CustomRequestWidgetFormValueKeys.DATE]);
                const dateValue = new Date(numericDateEpoch);
                return {
                    dateValue,
                    timeValue: numericDateEpoch
                };
            case FilterType.DROPDOWN_MULTIPLE:
                return formSavedRowObj[CustomRequestWidgetFormValueKeys.SELECTION] || [];
            default:
                return field.text;
        }
    }

    staticUpdateFields(value) {
        if (this.staticValueMap && this.FIRST_LABEL && this.SECOND_LABEL) {
            if (
                this.requestFormGenInput.fields &&
                this.requestFormGenInput.fields[0].label === this.FIRST_LABEL &&
                this.requestFormGenInput.fields[1].label ===
                    this.SECOND_LABEL &&
                Object.values(value)[0] &&
                this.value !==
                    String(Object.values(value)[0])
                        .trim()
                        .replace(/\s{2,}/g, ' ')
            ) {
                // Change in first dropdown
                let selectedValue = String(Object.values(value)[0]);
                selectedValue = selectedValue.trim();
                selectedValue = selectedValue.replace(/\s{2,}/g, ' ');
                this.value = selectedValue;
                this.updateControl.next({
                    action: UpdateAction.UPDATE_VALUE,
                    controls: [this.requestFormGenInput.fields[1].name],
                    value: []
                });
                const dependentDropdownValue =
                    this.staticValueMap[selectedValue];
                this.requestFormGenInput.fields[1].listData =
                    dependentDropdownValue;
            } else if (
                value &&
                this.value !== '' &&
                Object.values(value) &&
                Object.values(value)[0] === null &&
                this.requestFormGenInput.fields[0].label === this.FIRST_LABEL
            ) {
                this.value = '';
                this.updateControl.next({
                    action: UpdateAction.UPDATE_VALUE,
                    controls: [this.requestFormGenInput.fields[1].name],
                    value: []
                });

                this.requestFormGenInput.fields[1].listData = [];
            }
        }
    }
    prepareDataForApi(buttonRef: IButtonData) {
        if (this.requestFormGroupRef && this.requestFormGroupRef.value) {
            let isDateValid = true;

            const apiInput = this.requestFormGenInput.fields.map((each) => {
                const fieldKeyName = {};

                switch (each.fieldType) {
                    case FilterType.DROPDOWN_MULTIPLE:
                        fieldKeyName['selection'] =
                            this.requestFormGroupRef.value[each.name];
                        break;

                    case FilterType.DATE_TIME:
                        const dateTimeValue = () => {
                            const date =
                                this.requestFormGroupRef.value[each.name] &&
                                this.requestFormGroupRef.value[each.name]
                                    .dateValue
                                    ? this.requestFormGroupRef.value[each.name]
                                          .dateValue
                                    : null;
                            const time =
                                this.requestFormGroupRef.value[each.name] &&
                                this.requestFormGroupRef.value[each.name]
                                    .timeValue
                                    ? this.requestFormGroupRef.value[each.name]
                                          .timeValue
                                    : null;

                            if (!date || !time) {
                                isDateValid = false;
                            } else {
                                isDateValid = true;
                            }

                            const formattedDate = moment(
                                moment(date).format('YYYY-MM-DD') + ' ' + time
                            ).valueOf();

                            return formattedDate;
                        };

                        fieldKeyName['date'] = dateTimeValue();
                        break;

                    default:
                        fieldKeyName['inputString'] =
                            this.requestFormGroupRef.value[each.name];
                        break;
                }

                fieldKeyName['id'] = each.name;
                return fieldKeyName;
            });

            if (!isDateValid) {
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    null,
                    'Please input correct date and time'
                );
                buttonRef.loader = false;
                return;
            }

            const apiArgs = Helper.generateHitApiConfig(
                this.isEdit
                    ? this.widgetRef.widgetData.widgetInfo.update
                    : this.widgetRef.widgetData.widgetInfo.action
            );

            if (this.isEdit) {
                const formPreSavedData = this.modalInputData.data.formPreSavedData;
                apiArgs.intactUrl = apiArgs.url;
                apiArgs.url = apiArgs.url.replace(
                    '{request-id}',
                    formPreSavedData?.['Form ID']
                );
            }

            apiArgs.input = apiInput;
            apiArgs.function = () => {
                this.modalService.closeModal(null, this.modalInputData.modalId);
                this.widgetRef.notificationsService.showSnackBar(
                    Messages.REQUEST_FORM_SUBMITTED
                );
                this.enterDetailButton.disable = true;
                this.widgetRef.refreshWidget();
            };
            apiArgs.errorFunction = (error) => {
                buttonRef.loader = false;
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    error,
                    'Error while submitting request form'
                );
            };

            new HitApi(
                apiArgs,
                this.widgetRef.httpService,
                this.widgetRef.ngZone
            ).hitApi();
        }
    }
}
