<div class="tw-px-3">
    <div class="select-fields tw-pb-4 tw-font-semibold">Select Medium</div>
    <div
        class="table-search"
        *ngIf="integrations && integrations.length && showSearchBox"
    >
        <app-table-search
            class="search-box"
            [data]="integrations"
            (changed)="filteredIntegrations = $event"
        ></app-table-search>
    </div>
    <ng-container *ngIf="filteredIntegrations && filteredIntegrations.length">
        <div class="integration-index">
            <ng-container *ngFor="let integration of filteredIntegrations">
                <ng-container
                    *ngIf="
                        integration === IntegrationName.SLACK;
                        else integrationTemplate
                    "
                >
                    <ng-container
                        *ngIf="!(globalDataService.viaMicrosoftTeams | async)"
                    >
                        <ng-container
                            [ngTemplateOutlet]="integrationTemplate"
                        ></ng-container>
                    </ng-container>
                </ng-container>
                <ng-template #integrationTemplate>
                    <div
                        class="integration-item"
                        [class.selected]="selectedIntegrations.has(integration)"
                        [class.disabled]="
                            integrationsInfo.get(integration)
                                .integrationData === undefined ||
                            integrationsInfo.get(integration)
                                .integrationData === null ||
                            !integrationsInfo.get(integration).integrated
                        "
                        [matTooltip]="
                            integrationsInfo.get(integration)
                                .integrationData === null
                                ? integrationsInfo.get(integration).name +
                                  ' not integrated'
                                : integrationsInfo.get(integration)
                                      .integrationData === undefined
                                ? 'Loading ' +
                                  integrationsInfo.get(integration).name +
                                  ' Info'
                                : !integrationsInfo.get(integration).integrated
                                ? integrationsInfo.get(integration).name +
                                  ' not integrated'
                                : null
                        "
                    >
                        <ng-container
                            *ngIf="
                                integrationsInfo.get(integration)
                                    .integrationData === undefined
                            "
                        >
                            <mat-spinner [diameter]="16"></mat-spinner>
                            {{ integrationsInfo.get(integration).name }}
                        </ng-container>
                        <ng-container
                            *ngIf="
                                integrationsInfo.get(integration)
                                    .integrationData
                            "
                        >
                            <mat-checkbox
                                [class.disabled]="
                                    integrationsInfo.get(integration)
                                        .integrationData === undefined ||
                                    integrationsInfo.get(integration)
                                        .integrationData === null ||
                                    !integrationsInfo.get(integration)
                                        .integrated
                                "
                                [disabled]="
                                    integrationsInfo.get(integration)
                                        .integrationData === undefined ||
                                    integrationsInfo.get(integration)
                                        .integrationData === null ||
                                    !integrationsInfo.get(integration)
                                        .integrated
                                "
                                [checked]="
                                    selectedIntegrations.has(integration)
                                "
                                (change)="
                                    $event.checked
                                        ? selectedIntegrations.add(integration)
                                        : selectedIntegrations.delete(
                                              integration
                                          );
                                    emitSelectedValues()
                                "
                            >
                                {{ integrationsInfo.get(integration).name }}
                            </mat-checkbox>
                        </ng-container>

                        <ng-container
                            *ngIf="
                                integrationsInfo.get(integration)
                                    .integrationData === null
                            "
                        >
                            <i class="fas fa-exclamation-triangle"></i>
                            {{ integrationsInfo.get(integration).name }}
                        </ng-container>
                    </div>
                </ng-template>
            </ng-container>
        </div>
    </ng-container>
</div>
