<div class="button-container" *ngIf="!(widgetRef.loadingData | async)">
    <div class="request-button-container">
        <app-button-generator [buttonGenInput]="buttonInput">
        </app-button-generator>
    </div>
</div>
<app-table-generator
    [tableInput]="tableInputData"
    [widgetRef]="widgetRef"
    [tableData]="tablesData"
></app-table-generator>
