import { Injectable } from '@angular/core';
import { iot, mqtt } from 'aws-iot-device-sdk-v2';
import { Helper } from '../../classes/Helper';
import { Logger } from '../../classes/Logger';
import { IIotInput } from '../../interfaces/iot/IIotInput';

declare let AWS: any;

declare let Paho: any;

@Injectable({
    providedIn: 'root'
})
export class IotNewService {
    urlToClientMapping = {};

    constructor() {}

    makeConnection(iotInput: IIotInput): any {
        const client = this.getClient(iotInput);
        let counter = 0;

        client.on('connect', () => {
            client.subscribe(iotInput.topic, 1);
            iotInput.connectionSuccessCallback();
        });

        client.on('message', (topic, message) => {
            const data = new TextDecoder('utf-8').decode(message);
            if (data) {
                counter++;
            }
            const receivedDataObject = JSON.parse(data);
            const responseCounter = receivedDataObject.responseCounter;

            if (iotInput.topic === topic) {
                iotInput.dataReceivedCallback(
                    JSON.parse(data),
                    responseCounter,
                    counter
                );
            }
        });
        client.on('interrupt', (error) => {
            Logger.warn(error);
        });
        client.on('disconnect', () => {});

        const cleanUrl = Helper.cleanURL(window.location.pathname);
        if (cleanUrl in this.urlToClientMapping) {
            this.urlToClientMapping[cleanUrl].push(client);
        } else {
            this.urlToClientMapping[cleanUrl] = [];
            this.urlToClientMapping[cleanUrl].push(client);
        }
        return client;
    }

    getClient(iotInput: IIotInput) {
        const accessKeyId = iotInput.accessKeyId;
        const secretKey = iotInput.secretAccessKey;
        const host = iotInput.endpoint;
        const clientId = Helper.generateUniqueKey(32);
        const region = iotInput.region;
        const sessionToken = iotInput.sessionToken;

        const config =
            iot.AwsIotMqttConnectionConfigBuilder.new_with_websockets()
                .with_client_id(clientId)
                .with_credentials(region, accessKeyId, secretKey, sessionToken)
                .with_keep_alive_seconds(30)
                .with_endpoint(host)
                .with_clean_session(false)
                .with_reconnect_min_sec(4)
                .with_reconnect_max_sec(9999999)
                .build();

        const mqttClient = new mqtt.MqttClient();
        const client = mqttClient.new_connection(config);

        // Call connection failure if not established till 10Seconds
        setTimeout(() => {
            if (
                (client as any).connection.reconnecting &&
                !(client as any).connection.connected
            ) {
                iotInput.connectionFailureCallback();
            }
        }, 10000);

        return client;
    }

    clearIotConnections(newUrl: string, clearAll?: boolean) {
        Object.keys(this.urlToClientMapping)
            .filter((url) => {
                clearAll ||
                    (url !== newUrl &&
                        url !== '/' &&
                        !url.includes('customer_access'));
            })
            .forEach((url) => {
                const clients = this.urlToClientMapping[url];
                clients.forEach((client) => {
                    client
                        .disconnect()
                        .then((res) => {
                            Logger.info('Client Disconnected');
                        })
                        .catch((error) => {
                            Logger.warn('Error Disconnecting Client');
                        });
                });
                delete this.urlToClientMapping[url];
            });
    }
}
