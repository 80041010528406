<div
    class="breadcrumb"
    [hidden]="
        (widgetRef.loadingData | async) ||
        !(
            widgetRef.widgetLinkingData &&
            widgetRef.widgetLinkingData.breadCrumbs &&
            widgetRef.widgetLinkingData.breadCrumbs.length
        )
    "
>
    <div
        class="patch"
        *ngIf="
            widgetRef.widgetLinkingData &&
            widgetRef.widgetLinkingData.breadCrumbs &&
            widgetRef.widgetLinkingData.breadCrumbs.length
        "
    >
        <span
            class="item-name"
            (click)="$event.stopPropagation(); goBackDrillDown(true)"
            >Home
            <span class="arrow-icon" appIcon [data]="arrowRightIcon"></span>
        </span>

        <span
            class="item-name"
            [class.large]="isLast"
            *ngFor="
                let item of widgetRef.widgetLinkingData.breadCrumbs;
                index as i;
                last as isLast;
                count as count
            "
            (click)="
                $event.stopPropagation();
                goBackDrillDown(null, count - (i + 1), isLast)
            "
            >{{ item }}
            <span
                class="arrow-icon"
                *ngIf="!isLast"
                appIcon
                [data]="arrowRightIcon"
            ></span>
        </span>
    </div>
</div>

<div
    class="controls"
    *ngIf="
        !(widgetRef.loadingData | async) &&
        rowData &&
        rowData.length &&
        widgetRef.visibleSections.value.has(ViewType.TABLE)
    "
>
    <div class="tw-h-12 tw-items-center tw-flex table-search">
        <app-single-select-dropdown
            [style.minWidth.px]="161"
            *ngIf="
                widgetRef.apiResponse &&
                widgetRef.visibleSections &&
                widgetRef.visibleSections.value.has(ViewType.GRAPH)
            "
            [filterInfo]="filterInfo"
            [dummy]="
                filterInfo &&
                filterInfo.listData &&
                filterInfo.listData.length <= 1
            "
            [selectedValue]="optionSelected"
            [clearable]="false"
            (change)="onOptionsSelected($event)"
        >
        </app-single-select-dropdown>
        <div class="search-input-container" *ngIf="!widgetRef.lightState">
            <app-table-search
                cdkFocusInitial
                [searchFunction]="onQuickFilterChanged.bind(this)"
            ></app-table-search>
        </div>
    </div>
    <!-- <app-single-select-dropdown
   // [defaultItem]="optionSelected" (change)="onOptionsSelected($event)"
        *ngIf="!(widgetRef.loadingData|async) && (widgetRef.visibleSections|async) && (widgetRef.visibleSections|async).has(ViewType.GRAPH)"
        [listData]="widgetRef.apiResponse.dataMap['pie'] && widgetRef.apiResponse.dataMap['pie']['y']"
        (change)="onOptionsSelected($event)">
    </app-single-select-dropdown> -->
</div>

<div
    [ngClass]="
        (widgetRef.compareViewService.isCompareViewEnabled | async)
            ? 'compare-view'
            : 'widget-data-container'
    "
    *ngIf="!(widgetRef.loadingData | async) && rowData && rowData.length"
>
    <div
        class="graph-container"
        *ngIf="
            !(widgetRef.loadingData | async) &&
            (widgetRef.visibleSections | async).has(ViewType.GRAPH)
        "
    >
        <div
            class="tw-w-full tw-relative tw-flex tw-flex-col tw-justify-center tw-items-center graph-content"
        >
            <app-graph-generator [graphData]="graphData"></app-graph-generator>
            <div
                *ngIf="widgetRef.lightState"
                class="lightOrFull clickable"
                (click)="getFullAccess()"
            >
                <p>Get Full Access!</p>
                <p class="description">Graph Details in full version</p>
            </div>
            <div
                class="graph-overlay"
                *ngIf="
                    showGraphOverlay &&
                    widgetRef.visibleSections.value &&
                    widgetRef.visibleSections.value.has(ViewType.GRAPH)
                "
            >
                <div class="overlay-text">
                    <span>
                        <i class="fas fa-exclamation-triangle"></i>
                        {{ overlayText }}
                        <span class="underline" (click)="switchToTableView()">
                            Switch&nbsp;to&nbsp;Table&nbsp;View
                        </span>
                    </span>
                </div>
            </div>

            <div class="general">
                <span class="header-web-view">
                    {{
                        totalCost > 0
                            ? "Total Cost :"
                            : totalUsage > 0
                            ? "Total Usage :"
                            : ""
                    }}
                </span>
                &nbsp;
                <span class="value">
                    {{ totalCost > 0 ? currencySymbol : "" }}
                    {{
                        totalCost > 0
                            ? unparsedTotalCost
                            : totalUsage > 0
                            ? totalUsage
                            : ""
                    }}
                </span>
            </div>
        </div>
    </div>
    <div
        [ngClass]="
            widgetRef.widgetLinkingData
                ? 'table-container-compare-view'
                : 'table-container'
        "
        appResizeObserver
        *ngIf="
            !(widgetRef.loadingData | async) &&
            (widgetRef.visibleSections | async).has(ViewType.TABLE)
        "
    >
        <app-only-table-generator
            [widgetRef]="widgetRef"
            [tableInput]="tableInputData"
            [tableData]="filteredSearchData"
            [getRowStyle]="getRowStyle"
            [showSearchBox]="false"
            (rowClicked)="tableRowClicked($event['data'], true)"
            [hideControlBar]="true"
            (gridRef)="agGrid = $event"
            [autoFitColumns]="!(tableInputData.columns.length === 5)"
            [defaultColDef]="defaultColDef"
        ></app-only-table-generator>
    </div>
</div>
