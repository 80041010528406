import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { skip, take } from 'rxjs/operators';
import { State } from '../../enums/State';
import { Helper } from './../../classes/Helper';
import { SupportedFormat } from './../../enums/SupportedFormat';
import { TraversalPurpose } from './../../enums/TraversalPurpose';
import { NLevelRoutePipe } from './../../pipes/n-level-route-pipe/n-level-route-pipe.pipe';
import { GlobalDataService } from './../../services/global-data/global-data.service';
import { ViewHttpService } from './../../services/http/view-http/view-http.service';
import { WidgetHttpService } from './../../services/http/widget-http/widget-http.service';

@Component({
    selector: 'app-widget-selector',
    templateUrl: './widget-selector.component.html',
    styleUrls: ['./widget-selector.component.sass']
})
export class WidgetSelectorComponent implements OnInit {
    @Input() apiUrl: string;
    @Input() viewId: string;
    @Input() supportedFormats: SupportedFormat[];
    @Input() limit: number;
    @Input() selectedMap: Map<string, string>;
    @Input() selectedIds: string[];
    @Output() widgetsSelectedIds = new EventEmitter<string[]>();
    @Output() widgetsSelectedMap = new EventEmitter<Map<string, string>>();
    leafWidgets: IWidget[] = null;
    TraversalPurpose = TraversalPurpose;
    selectedWidgets: Map<string, string> = new Map();
    selectedWidgetIds: string[] = [];

    viewData = null;
    error = false;
    errorMessage = null;
    loader = false;

    State = State;

    constructor(
        public globalDataService: GlobalDataService,
        private widgetHttpService: WidgetHttpService,
        private viewHttpService: ViewHttpService
    ) {}

    ngOnInit(): void {
        if (this.viewId !== undefined) {
            this.generatView();
        } else {
            this.viewData = this.globalDataService.sideMenuStepOne;
        }
        if (this.selectedMap) {
            this.selectedWidgets = this.selectedMap;
        }
        if (this.selectedIds) {
            this.selectedWidgetIds = this.selectedIds;
            if (!this.selectedMap.size) {
                this.generateSelectedWidgetMap();
            }
        }
    }

    generatView() {
        this.error = false;
        this.loader = true;
        this.viewHttpService.getSidebarDataForView(
            this.viewId ? this.viewId : '',
            (response) => {
                this.viewData = response;
                this.loader = false;
            },
            (error) => {
                this.loader = false;
                this.error = true;
                this.errorMessage = Helper.extractErrorMessage(error);
            },
            this.apiUrl
        );
    }

    handleLeafNode(leafNodes: Map<number, any>) {
        const url = Helper.urlFormatter(
            new NLevelRoutePipe().transform(leafNodes)
        );
        const leafNode = leafNodes.get(url.split('/').length - 1);
        // this.lastLevelName = leafNode.name;
        this.widgetHttpService
            .getAllWidgetsFromLeafNode(leafNode.nextUrl)
            .subscribe((data) => {
                this.leafWidgets = data;
            });
    }

    trackByID(index: number, widget: IWidget) {
        return widget.id;
    }

    checkIfDisabled(widget: IWidget) {
        if (widget.widgetType === State.LITE) {
            return true;
        }
        if (
            (!widget.supportedFormats ||
                !widget.supportedFormats.length ||
                this.selectedWidgets.size >= this.limit) &&
            !this.selectedWidgetIds.includes(widget.id)
        ) {
            return true;
        }
        let disabled = true;

        widget.supportedFormats.every((sf) => {
            if (this.supportedFormats.includes(sf)) {
                disabled = false;
                return false;
            }
            return true;
        });
        return disabled;
    }

    toggleWidgetSelection(selection, widgetData: IWidget) {
        if (selection) {
            this.selectedWidgets.set(widgetData.id, widgetData.name);
            if (!this.selectedWidgetIds.includes(widgetData.id)) {
                this.selectedWidgetIds.push(widgetData.id);
            }
        } else {
            this.selectedWidgets.delete(widgetData.id);
            if (this.selectedWidgetIds.includes(widgetData.id)) {
                this.selectedWidgetIds = Helper.removeStringfromArray(
                    widgetData.id,
                    this.selectedWidgetIds
                );
            }
        }
        this.widgetsSelectedIds.emit(this.selectedWidgetIds);
        this.widgetsSelectedMap.emit(this.selectedWidgets);
    }

    generateSelectedWidgetMap() {
        this.selectedIds.forEach((widId) => {
            const widInfoObs = this.widgetHttpService.getWidgetInfo(widId);
            if (widInfoObs.value) {
                this.selectedMap.set(widId, widInfoObs.value.text);
            } else {
                widInfoObs.pipe(skip(1), take(1)).subscribe((widInfo) => {
                    this.selectedMap.set(widId, widInfo.text);
                });
            }
        });
    }
}

interface IWidget {
    id: string;
    name: string;
    supportedFormats: SupportedFormat[];
    widgetType?: State;
}
