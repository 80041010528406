import {
    ChangeDetectorRef,
    Component,
    HostBinding,
    NgZone,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, skip, take } from 'rxjs/operators';
import { DetailedSideNavigation } from 'src/app/shared/classes/DetailedSideNavigation';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { SvgData } from 'src/app/shared/classes/SvgData';
import { WidgetGeneratorComponent } from 'src/app/shared/components/widget-generator/widget-generator.component';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { IconType } from 'src/app/shared/enums/IconType';
import { PortletType } from 'src/app/shared/enums/PortletType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { SanitizeTypes } from 'src/app/shared/enums/SanitizeTypes';
import { IAssessmentStatus } from 'src/app/shared/interfaces/assessmentStatus/IAssessmentStatus';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IWidgetData } from 'src/app/shared/interfaces/widget/IWidgetData';
import { IWidgetUrlInfo } from 'src/app/shared/interfaces/widget/IWidgetUrlInfo';
import {
    AssessmentAuditTypes,
    AssessmentCacheService
} from 'src/app/shared/services/cache/assessment-cache/assessment-cache.service';
import { ConfigCacheService } from 'src/app/shared/services/cache/config-cache/config-cache.service';
import { SideMenuCacheService } from 'src/app/shared/services/cache/side-menu-cache/side-menu-cache.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { GlobalDataService } from 'src/app/shared/services/global-data/global-data.service';
import { WidgetHttpService } from 'src/app/shared/services/http/widget-http/widget-http.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { RedirectionHandleService } from 'src/app/shared/services/redirection-handle/redirection-handle.service';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';
import { SubSink } from 'subsink';
import { FilterStoreKey } from '../../../shared/enums/FilterStoreKey';
import { ISlideInfo } from '../../../shared/interfaces/carousel-generator/ISlideInfo';
import { ApiUrls } from '../../classes/ApiUrls';
import { WidgetCacheService } from './../../../shared/services/cache/widget-cache/widget-cache.service';
import { IotNewService } from './../../../shared/services/iot/iot-new.service';
import { AuditEvidenceSaveConfirmationService } from 'src/app/shared/services/assessment/audit-evidence-save-confirmation.service';

@Component({
    selector: 'app-dynamic-content',
    templateUrl: './dynamic-content.component.html',
    styleUrls: ['./dynamic-content.component.sass']
})
export class DynamicContentComponent implements OnInit, OnDestroy {
    @HostBinding('style.height') hostHeight: string = null;
    @HostBinding('style.display') hostDisplay: string = null;
    @HostBinding('style.flex-direction') hostFlexDir: string = null;

    subs = new SubSink();
    widgetSubs = new SubSink();
    widgetIds: string[];
    widgetData: Map<string, IWidgetData> = new Map<string, IWidgetData>();
    loadingWidgetsData = new BehaviorSubject(true);
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };

    carouselWidgetIds = [];
    currentPage = 1;
    carouselSlides: ISlideInfo[];

    widgetUrlInfo: IWidgetUrlInfo = null;
    pageNotFound = false;
    errorLoadingPage = false;
    SvgData = SvgData;
    SanitizeTypes = SanitizeTypes;
    assessmentOverview: boolean = false;
    PortletType = PortletType;
    ribbonPortletType: boolean;

    groupIds: string[];
    groupWidgetMap: Map<string, IWidgetListInfo[]> = new Map<
        string,
        IWidgetListInfo[]
    >();
    groupActiveWidgetMap: Map<string, number> = new Map<string, number>();
    fullHeightPortletTypes = [
        PortletType.CUSTOMER_SERVICE_LISTING,
        PortletType.CUSTOM_COST_MONITORING_CREATION,
        PortletType.ADD_WIDGET_DOCUMENTATION
    ];
    counter: number = 1;
    navigationPoints: any[] = [];
    DetailedSideNavigation = DetailedSideNavigation;
    toggleMenu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    @ViewChild('triggerMenu') matMenuTrigger: MatMenuTrigger;
    controlPointStatus: BehaviorSubject<IAssessmentStatus>;
    /**
     * To be used for storing control point nav data in assessment
     * to disable last control point next button
     */
    private sections: any[] = null;

    constructor(
        private ref: ChangeDetectorRef,
        public globalDataService: GlobalDataService,
        private widgetHttpService: WidgetHttpService,
        private filtersService: FiltersService,
        private configCache: ConfigCacheService,
        private router: Router,
        private widgetCacheService: WidgetCacheService,
        private redirectionHandleService: RedirectionHandleService,
        public ngZone: NgZone,
        private sideMenuCacheService: SideMenuCacheService,
        private notificationService: NotificationsService,
        public userDataCacheService: UserDataCacheService,
        public assessmentCacheService: AssessmentCacheService,
        private auditEvidenceSaveConfirmationService: AuditEvidenceSaveConfirmationService,
        private iotNewService: IotNewService
    ) {}

    ngOnInit() {
        this.controlPointStatus =
            this.notificationService.updateControlPointStatus;
        this.notificationService.updateControlPointStatus.next({
            statusName: this.widgetUrlInfo?.response['framework']['status'],

            statusColor: this.widgetUrlInfo?.response['framework']['colorCode'],
            score: this.widgetUrlInfo?.response['framework']['score']
        });
        if (
            this.redirectionHandleService.urlToRedirect &&
            this.redirectionHandleService.widgetIdToRedirect
        ) {
            this.performRedirection();
        } else if (!this.globalDataService.freshLogin) {
            const url = window.location.pathname;
            const key = Helper.convertUrlToKey(url);
            this.widgetCacheService.fetch(key, (value) => {
                if (!value && !this.globalDataService.returnToConsole) {
                    this.globalDataService.loadWidgetsFromApiArgs.next({
                        url: ApiUrls.LOGIN_REDIRECT_URL,
                        input: {
                            viewId: this.globalDataService.selectedView,
                            url
                        },
                        requestType: RequestType.POST,
                        uniqueIdentity: Symbol(),
                        function: null,
                        config: {
                            authorization: AuthorizationType.BEARER_TOKEN,
                            extraData: {
                                pageName: null
                            }
                        },
                        intactUrl: ApiUrls.LOGIN_REDIRECT_URL
                    });
                } else if (!this.globalDataService.returnToConsole) {
                    this.populateWidgetIds(
                        url,
                        this.populateWidgetsData.bind(this)
                    );
                }
            });
        }
        this.navigationPoints = this.sideMenuCacheService.getNavigationdpoints(
            this.globalDataService.selectedView
        );
        if (this.navigationPoints && this.navigationPoints.length) {
            this.navigationPoints.unshift({ name: 'Overview' });
            this.navigationPoints.splice(1, 1);
        }
        if (
            !(
                this.userDataCacheService.isAssessorView ||
                this.userDataCacheService.isAssesseeView
            )
        ) {
            this.globalDataService.fullPageLoader.next(false);
        }
        this.ref.detectChanges();

        // Loading from url
        // TODO: Will be implemented later based on use case
        // this.globalDataService.loadWidgets.subscribe(
        //   (widgetUrlInfo: IWidgetUrlInfo) => {
        //     this.currentPage = widgetUrlInfo.response.page;
        //     this.widgetUrlInfo = widgetUrlInfo;
        //     this.widgetIds = widgetUrlInfo.response.widgets;
        //     this.populateWidgetsData();
        //   }
        // );

        this.globalDataService.loadWidgetsFromWidgetId.subscribe(
            (widgetData) => {
                if (widgetData) {
                    this.widgetIds = widgetData.widgets;
                    const widgetUrlData: IWidgetUrlInfo = {
                        originalUrl: null,
                        pageName: widgetData.name,
                        response: {
                            page: 0,
                            totalPages: 0,
                            widgets: widgetData.widgets
                        }
                    };
                    this.widgetUrlInfo = widgetUrlData;
                    this.widgetUrlInfo.pageName = widgetData.name;
                    this.widgetUrlInfo.response.totalPages =
                        widgetData.totalPages ? widgetData.totalPages : 0;
                    this.configCache.pageNumber = this.widgetUrlInfo.response
                        .page
                        ? this.widgetUrlInfo.response.page
                        : 1;
                    this.prepareGroupWidgets(
                        widgetData.widgets.map((id) => ({
                            id: id
                        }))
                    );
                    this.populateWidgetsData();
                    this.router.navigateByUrl(
                        Helper.urlFormatter(widgetData.name)
                    );
                }
            }
        );

        this.globalDataService.loadWidgetsFromApiArgs.subscribe((apiArgs) => {
            if (this.userDataCacheService.isAssesseeView) {
                return;
            }
            if (apiArgs) {
                if (ApiUrls.IS_VIA_IP && apiArgs.url.charAt(0) === '/') {
                    apiArgs.url = apiArgs.url.substring(1);
                }
                const widgetUrlData: IWidgetUrlInfo = {
                    originalUrl: apiArgs.url,
                    pageName: apiArgs.config.extraData['pageName'],
                    response: null
                };
                this.setUpBasics(widgetUrlData);
                apiArgs.function = (widgetUrlInfo) => {
                    if (widgetUrlInfo.pageName === 'Widget Catalog') {
                        const routeData = [{ name: 'Widget Catalog' }];
                        const cleanUrl = '/widget_catalog';
                        this.globalDataService.breadcrumbData.next([
                            'Widget Catalog'
                        ]);
                        this.sideMenuCacheService.setPathSelectedData(
                            this.globalDataService.selectedView,
                            cleanUrl,
                            routeData
                        );
                    } else {
                        if (widgetUrlInfo.widgetsInfo) {
                            this.globalDataService.breadcrumbData.next(
                                widgetUrlInfo?.path
                            );
                            const routeData = widgetUrlInfo?.path?.map(
                                (ele) => ({
                                    name: ele
                                })
                            );
                            let selectedPath = Helper.urlFormatter(
                                widgetUrlInfo?.path?.join('/')
                            );
                            if (selectedPath?.charAt(0) !== '/') {
                                selectedPath = `/${selectedPath}`;
                            }
                            // Removing Brackets from the URL Path
                            const cleanUrl = Helper.cleanURL(selectedPath);
                            this.sideMenuCacheService.setPathSelectedData(
                                this.globalDataService.selectedView,
                                cleanUrl,
                                routeData
                            );
                        }
                    }
                    if (widgetUrlInfo.attributes) {
                        if (widgetUrlInfo.attributes.length) {
                            this.globalDataService.handleSelectedPath.next(
                                widgetUrlInfo.attributes
                            );
                        } else {
                            this.globalDataService.invalidUrl.next(true);
                            return;
                        }
                    }
                    widgetUrlData.pageName = widgetUrlInfo.pageName
                        ? widgetUrlInfo.pageName
                        : widgetUrlData.pageName;
                    widgetUrlData.response = widgetUrlInfo.widgetsInfo
                        ? widgetUrlInfo.widgetsInfo
                        : widgetUrlInfo;
                    widgetUrlData.path = widgetUrlInfo?.path
                        ? widgetUrlInfo?.path
                        : widgetUrlData.path;

                    this.currentPage =
                        (widgetUrlInfo.widgetsInfo
                            ? widgetUrlInfo.widgetsInfo.page
                            : widgetUrlInfo.page) + 1;
                    this.configCache.pageNumber = this.currentPage;
                    if (this.currentPage !== +this.configCache.pageNumber) {
                        this.currentPage = +this.configCache.pageNumber;
                        this.changePage(this.currentPage);
                        return;
                    }
                    this.handleWidgetResponse(widgetUrlData);
                };
                apiArgs.errorFunction = (error) => {
                    this.errorLoadingPage = true;
                };
                new HitApi(
                    apiArgs,
                    this.widgetHttpService,
                    this.widgetHttpService.ngZone
                ).hitApi();
            }
        });
        this.globalDataService.loadWidgetsForAssessee.subscribe((apiArgs) => {
            const widgetUrlData: IWidgetUrlInfo = {
                originalUrl: apiArgs.url,
                pageName: apiArgs.config.extraData['pageName'],
                response: null
            };
            this.setUpBasics(widgetUrlData);
            apiArgs.function = (widgetUrlInfo) => {
                widgetUrlData.response = widgetUrlInfo;
                this.currentPage = 1;
                this.configCache.pageNumber = this.currentPage;
                this.navigationPoints =
                    this.sideMenuCacheService.getNavigationdpoints(
                        this.globalDataService.selectedView
                    );
                if (this.navigationPoints && this.navigationPoints.length) {
                    this.navigationPoints.unshift({ name: 'Overview' });
                    this.navigationPoints.splice(1, 1);
                }
                this.handleWidgetResponse(widgetUrlData);
            };
            apiArgs.errorFunction = (error) => {
                this.errorLoadingPage = true;
            };
            new HitApi(
                apiArgs,
                this.widgetHttpService,
                this.widgetHttpService.ngZone
            ).hitApi();
        });
        this.toggleMenu.pipe(debounceTime(500)).subscribe((data) => {
            if (this.matMenuTrigger) {
                if (data) {
                    this.matMenuTrigger.openMenu();
                } else {
                    this.matMenuTrigger.closeMenu();
                }
            }
        });
    }
    handleNavigation(navigationData, level,
        shouldPauseNavigation?: (...argsToCache: any) => boolean | void,
        isActive?: boolean) {
        if (level !== 0) {
            return;
        }
        if (navigationData.name === 'Overview') {
            if (!isActive &&
                typeof shouldPauseNavigation === 'function' &&
                this.shouldPauseNavigation({
                    continueFunction: this.handleNavigation.bind(this),
                    navFunctionArgsToCache: [navigationData, level]
                })
            ) {
                return;
            }
            const routeData =
                this.globalDataService.sideMenuStepOne.landingPageAttributes;
            this.globalDataService.routingData.next(routeData);
            return;
        }
    }

    public shouldPauseNavigation(navArgsToCache: any) {
        if (
            this.auditEvidenceSaveConfirmationService.getIsUnsavedDataPresent()
        ) {
            this.auditEvidenceSaveConfirmationService.setIsNavigationTriggeredWithoutSave(
                navArgsToCache
            );
            return true;
        } else {
            return false;
        }
    }

    goToControlPoint(next: boolean,
        shouldPauseNavigation?: (...argsToCache: any) => boolean | void,
        isActive?: boolean) {
        if (!isActive &&
            typeof shouldPauseNavigation === 'function' &&
            this.shouldPauseNavigation({
                continueFunction: this.goToControlPoint.bind(this),
                navFunctionArgsToCache: [next]
            })
        ) {
            return;
        }

        let routeData =
            this.globalDataService.sideMenuStepOne.landingPageAttributes;
        let createAssessmentUrlOverview = '';
        if (this.assessmentCacheService.createAssessmentSideMenuData) {
            routeData =
                this.assessmentCacheService.createAssessmentSideMenuData;
            createAssessmentUrlOverview = routeData[1]['nextUrl'];
        }
        const apiArgs: IHitApi = {
            url: createAssessmentUrlOverview
                ? createAssessmentUrlOverview
                : ApiUrls.ASSESSE_LANDING_PAGE_API,
            input: {},
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (response) => {
                const sections = response['assesseOverview']
                    ? response['assesseOverview']['overview']['sections']
                    : response['assessorOverview']['overview']['sections'];
                this.sections = sections;
                const sectionId =
                    this.navigationPoints[this.navigationPoints.length - 1][
                        'id'
                    ];
                const leafNodes = this.getLeafNodes(sections);
                const index = leafNodes.findIndex(
                    (ele) => ele.id === sectionId
                );
                if (index !== -1) {
                    if (index === 0 && !next) {
                        const routeData =
                            this.globalDataService.sideMenuStepOne
                                .landingPageAttributes;
                        this.globalDataService.routingData.next(routeData);
                        return;
                    } else if (next && index === leafNodes.length - 1) {
                        this.notificationService.showSnackBar(
                            'No control points found.',
                            true
                        );
                    } else if (next) {
                        this.navigateToSection(leafNodes[index + 1]);
                    } else {
                        this.navigateToSection(leafNodes[index - 1]);
                    }
                }
            },
            errorFunction: (error) => {
                Helper.showErrorMessage(this.notificationService, error);
            }
        };
        if (!createAssessmentUrlOverview)
            apiArgs.url = apiArgs.url + '/' + routeData[1]['id'];

        new HitApi(
            apiArgs,
            this.widgetHttpService,
            this.widgetHttpService.ngZone
        ).hitApi();
    }

    getLeafNodes(sections) {
        let leafNodeArray = [];
        sections.forEach((section) => {
            if (section.leafNode) {
                leafNodeArray.push(section);
            } else if (
                !section.leafNode &&
                section.attributes &&
                section.attributes.length
            ) {
                const result = this.getLeafNodes(section.attributes);
                leafNodeArray = [...leafNodeArray, ...result];
            }
        });

        return leafNodeArray;
    }

    navigateToSection(section) {
        this.globalDataService.fullPageLoader.next(true);
        const apiArgs: IHitApi = {
            url: section['nextUrl'],
            input: {},
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (data) => {
                this.globalDataService.routingData.next(data);
            },
            errorFunction: (error) => {
                Helper.showErrorMessage(this.notificationService, error);
            }
        };

        new HitApi(
            apiArgs,
            this.widgetHttpService,
            this.widgetHttpService.ngZone
        ).hitApi();
    }
    setUpBasics(widgetUrlData) {
        this.subs.unsubscribe();
        this.widgetSubs.unsubscribe();
        this.emptyCarouselVariables();
        this.widgetIds = [];
        this.groupIds = [];
        this.loadingWidgetsData.next(true);
        this.emptyCarouselVariables();
        this.ref.detectChanges();
        this.pageNotFound = false;
        this.errorLoadingPage = false;
        this.widgetUrlInfo = widgetUrlData;
    }
    handleWidgetResponse(widgetUrlData) {
        this.widgetUrlInfo = widgetUrlData;
        if (widgetUrlData.response && widgetUrlData.response.widgets) {
            this.assessmentOverview = false;
            if (
                widgetUrlData.response.widgets &&
                widgetUrlData.response.widgets.length
            ) {
                this.prepareGroupWidgets(widgetUrlData.response.widgets);
            }
        } else if (
            widgetUrlData.response &&
            (widgetUrlData.response['assesseOverview'] ||
                widgetUrlData.response['assessorOverview'] ||
                widgetUrlData.response['framework'])
        ) {
            this.hostDisplay = 'block';
            this.hostHeight = '100%';
            if (widgetUrlData.response['framework']) {
                this.hostDisplay = 'flex';
                this.hostFlexDir = 'column';
            }
            this.assessmentOverview = true;
            this.loadingWidgetsData.next(false);
            this.ref.detectChanges();
            if (widgetUrlData.response && widgetUrlData.response['framework']) {
                this.globalDataService.frameworkData.next(
                    widgetUrlData.response
                );
            }
            if (this.globalDataService.fullPageLoader.getValue()) {
                this.globalDataService.fullPageLoader.next(false);
            }
        }
        this.widgetHttpService.widgetsListForUrl = {
            url: this.globalDataService.joinEnteredUrlBeforeLogin
                ? this.globalDataService.joinEnteredUrlBeforeLogin
                : window.location.pathname,
            data: this.widgetUrlInfo
        };
        if (!this.assessmentOverview) {
            this.populateWidgetsData();
        }
    }
    emptyCarouselVariables() {
        this.carouselSlides = [];
        this.ribbonPortletType = false;
        this.carouselWidgetIds = [];
    }

    populateWidgetIds(url: string, callback: Function) {
        this.pageNotFound = false;
        this.errorLoadingPage = false;
        const widgetIdsObs = this.widgetHttpService.getWidgetsFromUrl(url);
        if (widgetIdsObs.value) {
            this.widgetUrlInfo = widgetIdsObs.value;
            if (this.currentPage !== +this.configCache.pageNumber) {
                this.currentPage = +this.configCache.pageNumber;
                this.changePage(this.currentPage);
            } else {
                const widgetIds = widgetIdsObs.value.response.widgets;
                this.prepareGroupWidgets(widgetIds);
                this.ref.detectChanges();
                callback();
            }
        } else {
            this.widgetSubs.add(
                widgetIdsObs
                    .pipe(skip(1), take(1))
                    .subscribe((widgetUrlInfo) => {
                        if (widgetUrlInfo) {
                            this.widgetUrlInfo = widgetUrlInfo;
                            if (
                                this.currentPage !==
                                +this.configCache.pageNumber
                            ) {
                                this.currentPage = +this.configCache.pageNumber;
                                this.changePage(this.currentPage);
                            } else {
                                if (
                                    widgetUrlInfo.response &&
                                    widgetUrlInfo.response.widgets
                                ) {
                                    this.assessmentOverview = false;
                                    const widgetIds =
                                        widgetUrlInfo.response.widgets;
                                    this.prepareGroupWidgets(widgetIds);
                                    this.ref.detectChanges();
                                    callback();
                                } else if (
                                    widgetUrlInfo.response &&
                                    (widgetUrlInfo.response[
                                        'assesseOverview'
                                    ] ||
                                        widgetUrlInfo.response[
                                            'assessorOverview'
                                        ] ||
                                        widgetUrlInfo.response['framework'])
                                ) {
                                    this.assessmentOverview = true;
                                    this.loadingWidgetsData.next(false);
                                    this.ref.detectChanges();
                                }
                            }
                        } else {
                            this.loadingWidgetsData.next(false);
                            this.pageNotFound = true;
                            this.filtersService.generateFiltersData(
                                FilterStoreKey.WEBSITE_FILTERS,
                                new Map()
                            );
                        }
                    })
            );
        }
    }

    populateWidgetsData() {
        // Clean previous connections
        const cleanUrl = Helper.cleanURL(window.location.pathname);
        this.iotNewService.clearIotConnections(cleanUrl);

        this.globalDataService.refreshWidgetsForCache = [];
        this.globalDataService.widgetIdsOnCurrentPage = [...this.widgetIds];
        this.loadingWidgetsData.next(true);
        this.filtersService.filtersPageData.next(null);
        this.widgetData = new Map<string, IWidgetData>();
        this.widgetSubs.unsubscribe();
        if (this.widgetIds) {
            this.widgetIds.forEach((widgetId) => {
                this.widgetHttpService.loadWidgetsData(
                    this.widgetHttpService,
                    this.widgetSubs,
                    widgetId,
                    this.widgetData,
                    this.checkIfAllDataCollected.bind(this)
                );
            });
        }
    }

    checkIfAllDataCollected() {
        if (this.widgetIds.length === this.widgetData.size) {
            // All widgets data collected
            this.prepareFilterForActiveWidgets();
            this.ribbonPortletType = false;
            this.widgetIds.forEach((data) => {
                if (
                    this.widgetData.has(data) &&
                    this.widgetData.get(data).widgetInfo.portlet.type ===
                        PortletType.RIBBON
                ) {
                    this.ribbonPortletType = true;
                    this.carouselWidgetIds.push(data);
                }
            });

            this.carouselSlides = this.carouselWidgetIds.map((widgetId) => {
                return {
                    componentInput: this.widgetData.get(widgetId),
                    componentToLoad: WidgetGeneratorComponent
                };
            });
            this.loadingWidgetsData.next(false);
            this.globalDataService.allWidgetsLoaded.next(true);
        }
    }

    changePage(pageNumber, field?: HTMLInputElement) {
        pageNumber = +pageNumber;
        if (
            pageNumber < 1 ||
            pageNumber > this.widgetUrlInfo.response.totalPages
        ) {
            // Do Nothing
            if (field) {
                field.value = `${this.currentPage}`;
            }
        } else {
            this.emptyCarouselVariables();
            this.loadingWidgetsData.next(true);
            const lastPageNumber = this.currentPage;
            this.currentPage = pageNumber;
            this.configCache.pageNumber = this.currentPage;
            Helper.doScroll('dynamic-content', null, { x: 0, y: 0 });
            const url = Helper.generateNextPageUrl(
                this.widgetUrlInfo.originalUrl,
                pageNumber - 1
            );
            const apiArgs: IHitApi = {
                url,
                function: (response) => {
                    if (
                        response.page + 1 === this.currentPage &&
                        response.widgets.length
                    ) {
                        this.widgetUrlInfo.response = response;
                        // Updating Widget Ids
                        this.prepareGroupWidgets(response.widgets);
                        // Refrshing Content
                        this.populateWidgetsData();
                    } else if (response.widgets && !response.widgets.length) {
                        this.currentPage = lastPageNumber;
                        this.changePage(this.currentPage, field);
                    }
                    this.counter = 1;
                },
                errorFunction: () => {
                    if (this.counter === 5) {
                        window.location.reload();
                        return;
                    }
                    this.counter++;
                    this.currentPage = lastPageNumber;
                    this.changePage(this.currentPage, field);
                },
                input: {},
                intactUrl: this.widgetUrlInfo.originalUrl,
                requestType: RequestType.GET,
                uniqueIdentity: Symbol(),
                config: {
                    authorization: AuthorizationType.BEARER_TOKEN
                }
            };

            new HitApi(
                apiArgs,
                this.widgetHttpService,
                this.widgetHttpService.ngZone
            ).hitApi();
        }
    }

    widgetIdentify(index, widgetId) {
        return widgetId;
    }

    performRedirection() {
        const urlPath = this.redirectionHandleService.urlToRedirect;
        const widgetId = this.redirectionHandleService.widgetIdToRedirect;
        this.globalDataService.loadWidgetsFromApiArgs.next({
            url: ApiUrls.LOGIN_REDIRECT_URL,
            input: {
                viewId: this.globalDataService.selectedView,
                url: urlPath,
                widgetId: widgetId
            },
            requestType: RequestType.POST,
            uniqueIdentity: Symbol(),
            function: null,
            config: {
                authorization: AuthorizationType.BEARER_TOKEN,
                extraData: {
                    pageName: null
                }
            }
        });
        this.router.navigateByUrl(urlPath);

        this.globalDataService.allWidgetsLoaded.subscribe((response) => {
            if (response) {
                this.ngZone.runOutsideAngular(() => {
                    setTimeout(() => {
                        if (document.getElementById(widgetId)) {
                            Helper.doScroll(widgetId, 'end');
                        }
                    }, 1000);
                });
            }
        });

        this.redirectionHandleService.deleteKey(
            RedirectionHandleService.URL_TO_REDIRECT
        );
        this.redirectionHandleService.deleteKey(
            RedirectionHandleService.WIDGET_ID_TO_REDIRECT
        );
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    prepareGroupWidgets(widgetsInfoList: IWidgetListInfo[]) {
        this.widgetIds = widgetsInfoList.map((widgetObj) => widgetObj['id']);
        this.groupIds = [];
        this.groupWidgetMap = new Map();
        this.groupActiveWidgetMap = new Map();
        for (let index = 0; index < widgetsInfoList.length; index++) {
            const groupWidgets = [widgetsInfoList[index]];
            index++;
            while (
                index < widgetsInfoList.length &&
                widgetsInfoList[index].position > 0
            ) {
                groupWidgets.splice(
                    widgetsInfoList[index].position,
                    0,
                    widgetsInfoList[index]
                );
                index++;
            }
            const groupId = Helper.generateUniqueKey(10);
            this.groupIds.push(groupId);
            this.groupWidgetMap.set(groupId, groupWidgets);
            this.groupActiveWidgetMap.set(groupId, 0);
            index--;
        }
    }

    onActiveGroupWidgetChanged(index, groupId) {
        if (this.groupWidgetMap.has(groupId)) {
            const widgets = this.groupWidgetMap.get(groupId);
            if (widgets && widgets.length) {
                this.groupActiveWidgetMap.set(groupId, index);
                this.prepareFilterForActiveWidgets();
            }
        }
    }

    prepareFilterForActiveWidgets() {
        const activeWidgetIds = [];
        this.groupActiveWidgetMap.forEach((value, key) => {
            if (this.groupWidgetMap.has(key)) {
                const widgets = this.groupWidgetMap.get(key);
                if (widgets && widgets.length) {
                    activeWidgetIds.push(widgets[value].id);
                }
            }
        });
        this.filtersService.generateFiltersData(
            FilterStoreKey.WEBSITE_FILTERS,
            this.widgetData,
            activeWidgetIds
        );
        this.loadingWidgetsData.next(false);
    }

    public shouldShowScoreInStatus(controlPointStatus: IAssessmentStatus) {
        const score = controlPointStatus?.score;
        if (
            this.userDataCacheService.isAssessorView &&
            this.assessmentCacheService.assessmentAuditType ===
                AssessmentAuditTypes.CSP_SCORING &&
            typeof score !== 'undefined' &&
            score !== null
        ) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Intended to be used in asssessment only
     */
    public get isDisableNextButtonNavInAssessment(): boolean {
        if (this?.widgetUrlInfo?.response?.['framework']) {
            if (this.sections?.length) {
                return this.getIsLastControlPointBySectionList();
            } else {
                this.updateSectionList();
            }
        }
        return false;
    }

    /**
     * Intended to be used in asssessment only
     */
    private getIsLastControlPointBySectionList(): boolean {
        if (this.sections?.length) {
            const sectionId =
                this.navigationPoints[this.navigationPoints.length - 1]['id'];
            const leafNodes = this.getLeafNodes(this.sections);
            const index = leafNodes.findIndex((ele) => ele.id === sectionId);
            if (index !== -1) {
                if (index === leafNodes.length - 1) {
                    return true;
                }
            }
            return false;
        } else {
            return false;
        }
    }

    /**
     * update sections list from indexedDB data in case if it is empty
     * Intended to be used in asssessment only
     */
    private updateSectionList() {
        const url = window.location.pathname?.split('/')?.[1];
        const key = Helper.convertUrlToKey(url);
        this.widgetCacheService.fetch(`${key}assessment_overview`, (value) => {
            if (value?.response) {
                const response = value?.response;
                this.sections = response['assesseOverview']
                    ? response['assesseOverview']['overview']['sections']
                    : response['assessorOverview']['overview']['sections'];
            }
        });
    }

}

interface IWidgetListInfo {
    id: string;
    name: string;
    group: number;
    position: number;
}
