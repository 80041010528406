import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { FilterStoreKey } from 'src/app/shared/enums/FilterStoreKey';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalAction } from 'src/app/shared/enums/ModalAction';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { CreateReportFirstStepComponent } from '../../../modal-templates/report-listing-modal/create-report-first-step/create-report-first-step.component';
import { CreateReportSecondStepComponent } from '../../../modal-templates/report-listing-modal/create-report-second-step/create-report-second-step.component';
import { CreateReportThirdStepComponent } from '../../../modal-templates/report-listing-modal/create-report-third-step/create-report-third-step.component';
import { ButtonType } from './../../../../enums/ButtonType';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { ICardsGeneratorInput } from './../../../../interfaces/card-generator/ICardsGeneratorInput';

@Component({
    selector: 'app-report-listing',
    templateUrl: './report-listing.component.html',
    styleUrls: ['./report-listing.component.sass']
})
export class ReportListingComponent implements OnInit {
    widgetRef: Widget;
    cardInput: ICardsGeneratorInput;
    lightState: boolean;

    constructor(
        widgetData: WidgetInjectedData,
        private multiStepFormService: MultiStepFormService,
        private modalService: ModalService
    ) {
        this.widgetRef = widgetData.widgetRef;
        if (this.widgetRef.lightState) {
            this.lightState = true;
        }
    }
    ngOnInit(): void {
        this.cardInput = {
            widgetIconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-list-ol'
            },
            cardTitle: {
                infoTitle: 'Report Name',
                infoKey: 'reportName'
            },
            noDataMessage: 'No reports available',
            infoData: [
                {
                    infoTitle: 'Created By',
                    infoKey: 'createdBy|email'
                },
                {
                    infoTitle: 'Frequency',
                    infoKey: 'cronExpression',
                    infoRenderer: (infoData) => {
                        return new TitleCasePipe().transform(
                            infoData['reportFilters']['reportTimespan']
                        );
                    }
                }
            ],
            buttons: [
                {
                    buttonName: 'New Report',
                    buttonType: ButtonType.ICON,
                    buttonColorType: ButtonColorType.PRIMARY,
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-plus'
                    },
                    disable: this.lightState,
                    hoverText: 'New Report',
                    function: (buttonData: IButtonGeneratorInput) => {
                        if (buttonData.disable) {
                            this.openGetFullAccessModal();
                            return;
                        }
                        this.create();
                    }
                }
            ],
            cardButtons: [
                {
                    buttonName: null,
                    buttonType: ButtonType.ICON,
                    buttonColorType: ButtonColorType.SECONDARY,
                    hoverText: 'Edit Report',
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-edit'
                    },
                    function: (buttonRef: IButtonGeneratorInput, cardData) => {
                        // Edit Report
                        this.update(cardData);
                    }
                },
                {
                    buttonName: null,
                    buttonType: ButtonType.ICON,
                    buttonColorType: ButtonColorType.WARN,
                    hoverText: 'Delete Report',
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-trash'
                    },
                    function: (buttonRef: IButtonGeneratorInput, cardData) => {
                        // Delete Report
                        this.delete(cardData);
                    }
                }
            ],
            titleActions: [
                {
                    buttonName: '',
                    showLoader: true,
                    function: (buttonRef: IButtonGeneratorInput, cardData) => {
                        // buttonRef.loader = true;
                        this.toggleScheduler(cardData);
                    },
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-power-off'
                    },
                    ngStyleRenderer: (cardData) => {
                        return { color: cardData.active ? 'green' : 'red' };
                    },
                    hoverTextRenderer: (cardData) => {
                        return cardData.active
                            ? 'Disable Report'
                            : 'Enable Report';
                    }
                }
            ],
            listExtraction: {
                type: 'DIRECT'
            },
            bulkActions: [
                {
                    buttonName: 'Delete',
                    buttonType: ButtonType.ICON,
                    buttonColorType: ButtonColorType.WARN,
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-trash',
                        extraClass: 'color-warn'
                    },
                    hoverText: 'Delete Selected Reports',
                    showLoader: false,
                    disable: this.lightState,
                    function: (
                        buttonRef: IButtonGeneratorInput,
                        selectedCards,
                        bulkLoader: BehaviorSubject<boolean>
                    ) => {
                        if (buttonRef.disable) {
                            this.openGetFullAccessModal();
                            return;
                        }
                        this.modalService.openConfirmationModal({
                            modalName: 'Delete Reports',
                            modalIcon: {
                                type: IconType.FONTAWSOME,
                                class: 'fas fa-trash'
                            },
                            contextIcon: {
                                type: IconType.FONTAWSOME,
                                class: 'fas fa-exclaimation-triangle'
                            },
                            confirmationMessage:
                                'Are you sure you want to delete selected reports ?',
                            buttonColorType: ButtonColorType.WARN,
                            buttonText: 'Delete',
                            loaderOnExec: true,
                            function: (modalId: Symbol) => {
                                bulkLoader.next(true);
                                // Bulk Delete
                                const batchDeleteInput = {
                                    ids: selectedCards.map(
                                        (selCard) => selCard.id
                                    )
                                };
                                const apiArgs: IHitApi = {
                                    url: `${ApiUrls.REPORT_ENDPOINT}/batch`,
                                    intactUrl: `${ApiUrls.REPORT_ENDPOINT}/{batch}`,
                                    input: batchDeleteInput,
                                    uniqueIdentity: Symbol(),
                                    requestType: RequestType.DELETE,
                                    config: {
                                        authorization:
                                            AuthorizationType.BEARER_TOKEN
                                    },
                                    function: (response) => {
                                        this.widgetRef.notificationsService.showSnackBar(
                                            'Reports deleted successfully'
                                        );
                                        bulkLoader.next(false);
                                        this.widgetRef.refreshWidget();
                                    },
                                    errorFunction: (error) => {
                                        bulkLoader.next(false);
                                        this.widgetRef.notificationsService.showSnackBar(
                                            error &&
                                                error.error &&
                                                error.error.message
                                                ? error.error.message
                                                : 'Error deleting reports',
                                            true
                                        );
                                        this.widgetRef.refreshWidget();
                                    }
                                };
                                this.widgetRef.modalService.closeModal(
                                    null,
                                    modalId
                                );
                                new HitApi(
                                    apiArgs,
                                    this.widgetRef.httpService,
                                    this.widgetRef.ngZone
                                ).hitApi();
                            }
                        });
                    }
                },
                {
                    buttonName: 'Enable',
                    buttonType: ButtonType.ICON,
                    buttonColorType: ButtonColorType.PRIMARY,
                    hoverText: 'Enable Selected Reports',
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-power-off',
                        extraClass: 'color-success'
                    },
                    disable: this.lightState,
                    function: (
                        buttonData: IButtonGeneratorInput,
                        selectedCards,
                        bulkLoader: BehaviorSubject<boolean>
                    ) => {
                        if (buttonData.disable) {
                            this.openGetFullAccessModal();
                            return;
                        }
                        // Bulk Enable
                        bulkLoader.next(true);
                        const batchEnableInput = {
                            ids: selectedCards.map((selCard) => selCard.id)
                        };
                        const apiArgs: IHitApi = {
                            url: `${ApiUrls.REPORT_ENDPOINT}/batchEnable`,
                            intactUrl: `${ApiUrls.REPORT_ENDPOINT}/{batchEnable}`,
                            input: batchEnableInput,
                            uniqueIdentity: Symbol(),
                            requestType: RequestType.PATCH,
                            config: {
                                authorization: AuthorizationType.BEARER_TOKEN
                            },
                            function: (response) => {
                                this.widgetRef.notificationsService.showSnackBar(
                                    'Reports enabed successfully'
                                );
                                bulkLoader.next(false);
                                this.widgetRef.refreshWidget();
                            },
                            errorFunction: (error) => {
                                bulkLoader.next(false);
                                this.widgetRef.notificationsService.showSnackBar(
                                    error && error.error && error.error.message
                                        ? error.error.message
                                        : 'Error enabling reports',
                                    true
                                );
                                this.widgetRef.refreshWidget();
                            }
                        };
                        new HitApi(
                            apiArgs,
                            this.widgetRef.httpService,
                            this.widgetRef.ngZone
                        ).hitApi();
                    }
                },
                {
                    buttonName: 'Disable',
                    buttonType: ButtonType.ICON,
                    buttonColorType: ButtonColorType.PRIMARY,
                    hoverText: 'Enable Selected Reports',
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-power-off',
                        extraClass: 'color-warn'
                    },
                    disable: this.lightState,
                    function: (
                        buttonData: IButtonGeneratorInput,
                        selectedCards,
                        bulkLoader: BehaviorSubject<boolean>
                    ) => {
                        if (buttonData.disable) {
                            this.openGetFullAccessModal();
                            return;
                        }
                        // Bulk Disable
                        bulkLoader.next(true);
                        const batchDeleteInput = {
                            ids: selectedCards.map((selCard) => selCard.id)
                        };
                        const apiArgs: IHitApi = {
                            url: `${ApiUrls.REPORT_ENDPOINT}/batchDisable`,
                            intactUrl: `${ApiUrls.REPORT_ENDPOINT}/{batchDisable}`,
                            input: batchDeleteInput,
                            uniqueIdentity: Symbol(),
                            requestType: RequestType.PATCH,
                            config: {
                                authorization: AuthorizationType.BEARER_TOKEN
                            },
                            function: (response) => {
                                bulkLoader.next(false);
                                this.widgetRef.notificationsService.showSnackBar(
                                    'Reports disabled successfully'
                                );
                                this.widgetRef.refreshWidget();
                            },
                            errorFunction: (error) => {
                                bulkLoader.next(false);
                                this.widgetRef.notificationsService.showSnackBar(
                                    error && error.error && error.error.message
                                        ? error.error.message
                                        : 'Error disabling reports',
                                    true
                                );
                                this.widgetRef.refreshWidget();
                            }
                        };
                        new HitApi(
                            apiArgs,
                            this.widgetRef.httpService,
                            this.widgetRef.ngZone
                        ).hitApi();
                    }
                }
            ],
            selection: 'multiple',
            disableSelectAll: this.lightState,
            afterResponse: (response: any) => {
                if (this.lightState) {
                    if (response && response.length >= 1) {
                        this.cardInput.buttons.forEach(
                            (button) => (button.disable = true)
                        );
                    } else {
                        this.cardInput.buttons.forEach(
                            (button) => (button.disable = false)
                        );
                    }
                }
            }
        };
    }

    openGetFullAccessModal() {
        Helper.getFullAccess(
            this.widgetRef.modalService,
            this.widgetRef.widgetData.widgetInfo.portlet.type
        );
    }

    create() {
        const modalInfo = {
            label: 'Create Report',
            purpose: ModalAction.CREATE
        };
        this.setModalDataAndOpen(modalInfo, null);
    }

    update(item) {
        const modalInfo = {
            label: 'Update Report',
            purpose: ModalAction.UPDATE
        };
        this.setModalDataAndOpen(modalInfo, item);
    }

    setModalDataAndOpen(modalInfo, stepData) {
        this.widgetRef.filterService.filtersDataTemp.delete(
            FilterStoreKey.REPORT_FILTERS
        );
        this.widgetRef.filterService.filtersData.delete(
            FilterStoreKey.REPORT_FILTERS
        );
        const modalData: IModalData = {
            modalName: modalInfo.label,
            modalHeightVh: 98,
            modalWidthVw: 85,
            modalIcon: {
                class: 'fas fa-file-invoice',
                type: IconType.FONTAWSOME
            } as IIcon,
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            modalType: ModalType.MIDDLE,
            modalPurpose: modalInfo.purpose,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: CreateReportFirstStepComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                stepData,
                                edit: stepData ? true : false
                            }
                        }
                    },
                    stepName: 'Select Widgets'
                },
                {
                    stepData: {
                        componentToLoad: CreateReportSecondStepComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                stepData,
                                edit: stepData ? true : false
                            }
                        }
                    },
                    stepName: 'Filters'
                },
                {
                    stepData: {
                        componentToLoad: CreateReportThirdStepComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                stepData,
                                edit: stepData ? true : false
                            }
                        }
                    },
                    stepName: 'Schedule and Download'
                }
            ]
        };
        this.modalService.openModal(modalData);
    }

    delete(item) {
        this.modalService.openConfirmationModal({
            modalName: 'Delete Report',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-trash'
            },
            contextIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-exclaimation-triangle'
            },
            confirmationMessage: 'Are you sure you want to delete report ?',
            buttonColorType: ButtonColorType.WARN,
            buttonText: 'Delete',
            loaderOnExec: true,
            function: (modalId: Symbol) => {
                this.deleteApi.call(this, item, modalId);
            }
        });
    }
    deleteApi(report, modalId: Symbol) {
        const deleteArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.delete
        );
        deleteArgs.intactUrl = deleteArgs.url;
        deleteArgs.url = deleteArgs.url.replace('{report-id}', report.id);
        deleteArgs.input = {};
        deleteArgs.function = (response) => {
            this.widgetRef.notificationsService.showSnackBar(
                'Report deleted successfully'
            );
            this.widgetRef.modalService.closeModal(null, modalId);
            this.widgetRef.refreshWidget();
        };
        deleteArgs.errorFunction = (error) => {
            this.widgetRef.notificationsService.showSnackBar(
                error && error.error.message
                    ? error.error.message
                    : 'Error deleting report',
                true
            );
            this.widgetRef.modalService.closeModal(null, modalId);
        };
        new HitApi(
            deleteArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    toggleScheduler(item): void {
        let status = '';
        const widgetRef: Widget = this.widgetRef;
        const apiArgs: IHitApi = {
            url: ``,
            requestType: RequestType.PATCH,
            input: {},
            function: (response) => {
                item.active = !item.active;
            },
            errorFunction: () => {
                this.widgetRef.refreshWidget();
            },
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN,
                ignoreBaseUrl: false
            }
        };
        if (item.active) {
            status = 'disable';
        } else {
            status = 'enable';
        }
        apiArgs.url = `${ApiUrls.REPORT_ENDPOINT}/${status}/${item.id}`;
        apiArgs.intactUrl = `${ApiUrls.REPORT_ENDPOINT}/${status}/{report-id}`;
        new HitApi(apiArgs, widgetRef.httpService, widgetRef.ngZone).hitApi();
    }
}
