<div class="tw-h-full py-2 px-4">
    <mat-tab-group
        #tabGroup
        mat-stretch-tabs
        (selectedTabChange)="changeTabs($event.tab.textLabel)"
        mat-align-tabs="center"
    >
        <mat-tab [label]="Tab.DOWNLOAD_AND_MESSAGING">
            <div class="tw-overflow-hidden tw-flex tab-content">
                <app-common-integrations-index
                    class="tw-mt-4"
                    [initialSelection]="initialSelection"
                    [integrations]="[
                        IntegrationName.DOWNLOAD_REPORT,
                        IntegrationName.EMAIL,
                        IntegrationName.O365,
                        IntegrationName.GMAIL,
                        IntegrationName.SLACK
                    ]"
                    (selected)="integrationsChanged($event)"
                    (integrationInfoFunc)="getIntegrationData = $event"
                ></app-common-integrations-index>
                <div
                    class="tw-flex-1 tw-py-3 tw-px-5 tw-overflow-auto integration-data"
                >
                    <!-- Download Report -->
                    <div class="tw-mb-3 download-report">
                        <app-form-generator
                            *ngIf="downloadReportFormGenInput"
                            [formGeneratorInput]="downloadReportFormGenInput"
                            (formGroupRef)="downloadReportFormGroup = $event"
                        ></app-form-generator>
                    </div>

                    <!-- Email -->
                    <div
                        class="tw-mb-3 email"
                        *ngIf="
                            selectedIntegrations.has(IntegrationName.EMAIL) ||
                            selectedIntegrations.has(IntegrationName.GMAIL) ||
                            selectedIntegrations.has(IntegrationName.O365)
                        "
                    >
                        <app-form-generator
                            *ngIf="sendEmailFormGenInput"
                            [formGeneratorInput]="sendEmailFormGenInput"
                            (formGroupRef)="sendEmailFormGroup = $event"
                        ></app-form-generator>
                    </div>

                    <!-- Slack -->
                    <div
                        class="tw-mb-3 slack"
                        *ngIf="
                            selectedIntegrations &&
                            selectedIntegrations.has(IntegrationName.SLACK)
                        "
                    >
                        <app-form-generator
                            *ngIf="slackFormGenInput"
                            [formGeneratorInput]="slackFormGenInput"
                            (formGroupRef)="slackFormGroup = $event"
                        ></app-form-generator>
                    </div>

                    <!-- O365 Handled From Email-->
                    <!-- Gmail Handled From Email-->
                </div>
            </div>
        </mat-tab>
        <mat-tab [disabled]="!enableSaveAndSchedule()">
            <ng-template mat-tab-label>
                <label
                    [class.disabled]="!enableSaveAndSchedule()"
                    class="labelHeading clickable"
                    [matTooltip]="
                        !enableSaveAndSchedule() ? SAVE_DISABLE_MESSAGE : null
                    "
                    >{{ Tab.SAVE_AND_SCHEDULE }}</label
                >
            </ng-template>
            <div
                class="tw-overflow-hidden tab-content save-and-schedule-content"
            >
                <app-form-generator
                    *ngIf="reportFrequencyFormGenInput"
                    [formGeneratorInput]="reportFrequencyFormGenInput"
                    (formGroupRef)="reportFrequencyFormGroup = $event"
                ></app-form-generator>
                <ng-container
                    *ngIf="
                    domainIdSet.has(domain) &&
                        reportFrequencyFormGroup &&
                        (reportFrequencyFormGroup.get('reportFrequency')
                            .value === 'monthly' ||
                            reportFrequencyFormGroup.get('reportFrequency')
                                .value === 'daily')
                    "
                >
                    <app-form-generator
                        *ngIf="timeFormGenInput"
                        [formGeneratorInput]="timeFormGenInput"
                        (formGroupRef)="timeFormGroup = $event"
                    ></app-form-generator>
                </ng-container>
                <ng-container
                    *ngIf="
                        reportFrequencyFormGroup &&
                        reportFrequencyFormGroup.get('reportFrequency')
                            .value === 'weekly'
                    "
                >
                    <app-form-generator
                        [formGeneratorInput]="receiveReportFormGenInput"
                        (formGroupRef)="receiveReportFormGroup = $event"
                    ></app-form-generator>
                </ng-container>
                <ng-container
                    *ngIf="
                        reportFrequencyFormGroup &&
                        reportFrequencyFormGroup.get('reportFrequency')
                            .value === 'custom'
                    "
                >
                    <app-form-generator
                        [formGeneratorInput]="billingDayFormGenInput"
                        (formGroupRef)="billingDayFormGroup = $event"
                    ></app-form-generator>
                </ng-container>
            </div>
        </mat-tab>
    </mat-tab-group>
    <div class="tw-flex tw-justify-between controls">
        <app-button-generator
            class="tw-mr-4"
            *ngIf="backButton"
            [buttonGenInput]="backButton"
        ></app-button-generator>

        <div class="tw-flex">
            <app-button-generator
                class="tw-mr-4"
                [class.disabled]="downloadLoader"
                *ngIf="
                    selectedIntegrations &&
                    selectedIntegrations.has(IntegrationName.DOWNLOAD_REPORT) &&
                    selectedTab === Tab.DOWNLOAD_AND_MESSAGING &&
                    downloadButton
                "
                [buttonGenInput]="downloadButton"
            ></app-button-generator>

            <app-button-generator
                class="tw-mr-4"
                [class.disabled]="sendLoader"
                *ngIf="
                    ((selectedIntegrations.has(
                        IntegrationName.DOWNLOAD_REPORT
                    ) &&
                        selectedIntegrations.size > 1) ||
                        (!selectedIntegrations.has(
                            IntegrationName.DOWNLOAD_REPORT
                        ) &&
                            selectedIntegrations.size >= 1)) &&
                    selectedTab === Tab.DOWNLOAD_AND_MESSAGING &&
                    sendButton
                "
                [buttonGenInput]="sendButton"
            ></app-button-generator>

            <app-button-generator
                class="tw-mr-4"
                *ngIf="
                    selectedTab === Tab.DOWNLOAD_AND_MESSAGING &&
                    saveScheduleButton
                "
                [class.disabled]="!enableSaveAndSchedule()"
                [buttonGenInput]="saveScheduleButton"
            ></app-button-generator>

            <app-button-generator
                class="tw-mr-4"
                *ngIf="
                    selectedTab === Tab.SAVE_AND_SCHEDULE &&
                    lastSaveScheduleButton
                "
                [class.disabled]="
                    (selectedTab === Tab.SAVE_AND_SCHEDULE && saveLoader) ||
                    !enableSaveAndSchedule()
                "
                [buttonGenInput]="lastSaveScheduleButton"
            ></app-button-generator>
        </div>
    </div>
</div>
