<form class="form">
    <div class="form-data">
        <app-form-generator
            [formGeneratorInput]="reportNameFormGenInput"
            (formGroupRef)="reportNameFormGroup = $event"
        ></app-form-generator>
        <app-widget-selector
            [supportedFormats]="[SupportedFormat.REPORT]"
            [selectedMap]="widgetsSelectedMap"
            [selectedIds]="widgetsSelected"
            [limit]="GlobalConfiguration.REPORT_LIMIT"
            [apiUrl]="ApiUrls.GOVERNANCE_SIDE_MENU_DATA_API_PATH"
            [viewId]="configCacheService.viewId"
            (widgetsSelectedIds)="
                widgetsSelected = $event; noWidgetsSelected = false
            "
            (widgetsSelectedMap)="widgetsSelectedMap = $event"
        ></app-widget-selector>
        <div class="no-widgets" *ngIf="noWidgetsSelected">
            <i class="fas fa-exclamation-triangle color-warn"></i>
            Select atleast one widget
        </div>
    </div>
    <div class="form-controls">
        <app-button-generator
            *ngIf="buttonGenInput"
            [buttonGenInput]="buttonGenInput"
        ></app-button-generator>
    </div>
</form>
