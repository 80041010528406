import { Injectable } from '@angular/core';
import { CacheMain } from 'src/app/shared/classes/CacheMain';

@Injectable({
    providedIn: 'root'
})
export class AssessmentCacheService extends CacheMain {
    constructor() {
        const WIDGET_CACHE_PREFIX = 'ASSESSMENT_CACHE';
        super(WIDGET_CACHE_PREFIX, true);
    }
    set defaultViewId(viewId) {
        this.store(AssessmentKey.DEFAULT_VIEW_ID, viewId);
    }
    get defaultViewId() {
        return this.fetch(AssessmentKey.DEFAULT_VIEW_ID);
    }
    set assessmentView(assessmentView: boolean) {
        this.store(AssessmentKey.ASSESSMENT_VIEW, assessmentView);
    }
    get assessmentView(): boolean {
        return this.fetch(AssessmentKey.ASSESSMENT_VIEW);
    }
    set mainViewPath(path: string) {
        this.store(AssessmentKey.MAIN_VIEW_PATH, path);
    }
    get mainViewPath(): string {
        return this.fetch(AssessmentKey.MAIN_VIEW_PATH);
    }
    set widgetId(id: string) {
        this.store(AssessmentKey.WIDGET_ID, id);
    }
    get widgetId(): string {
        return this.fetch(AssessmentKey.WIDGET_ID);
    }
    set assessmentId(id: string) {
        this.store(AssessmentKey.ASSESSMENT_ID, id);
    }
    get assessmentId(): string {
        return this.fetch(AssessmentKey.ASSESSMENT_ID);
    }

    set assessmentName(id: string) {
        this.store(AssessmentKey.ASSESSMENT_NAME, id);
    }
    get assessmentName(): string {
        return this.fetch(AssessmentKey.ASSESSMENT_NAME);
    }

    set lastActiveView(viewId: string) {
        this.store(AssessmentKey.LAST_ACTIVE_VIEW, viewId);
    }
    get lastActiveView(): string {
        return this.fetch(AssessmentKey.LAST_ACTIVE_VIEW);
    }
    set assessmentStatus(status: string) {
        this.store(AssessmentKey.ASESSMENT_STATUS, status);
    }
    get assessmentStatus(): string {
        return this.fetch(AssessmentKey.ASESSMENT_STATUS);
    }
    set assessmentData(data: Object) {
        this.store(AssessmentKey.ASSESSMENT_DATA, data);
    }
    get assessmentData(): Object {
        return this.fetch(AssessmentKey.ASSESSMENT_DATA);
    }
    set createAssessmentSideMenuData(data: Object) {
        this.store(AssessmentKey.CREATE_ASSESSMENT_LIST_DATA, data);
    }
    get createAssessmentSideMenuData(): Object {
        return this.fetch(AssessmentKey.CREATE_ASSESSMENT_LIST_DATA);
    }
    set assessmentAuditType(auditType: string) {
        this.store(AssessmentKey.AUDIT_TYPE, auditType);
    }
    get assessmentAuditType(): string {
        return this.fetch(AssessmentKey.AUDIT_TYPE);
    }
}

export enum AssessmentKey {
    DEFAULT_VIEW_ID = 'DEFAULT_VIEW_ID',
    LAST_ACTIVE_VIEW = 'LAST_ACTIVE_VIEW',
    ASSESSMENT_VIEW = 'ASSESSMENT_VIEW',
    MAIN_VIEW_PATH = 'MAIN_VIEW_PATH',
    WIDGET_ID = 'WIDGET_ID',
    ASSESSMENT_ID = 'ASSESSMENT_ID',
    ASSESSMENT_NAME = 'ASSESSMENT_NAME',
    ASESSMENT_STATUS = 'ASSESSMENT_STATUS',
    ASSESSMENT_DATA = 'ASSESSMENT_DATA',
    CREATE_ASSESSMENT_LIST_DATA = 'CREATE_ASSESSMENT_LIST_DATA',
    AUDIT_TYPE = 'AUDIT_TYPE'
}

export enum AssessmentAuditTypes {
    HP = 'Hp',
    MATURITY = 'Maturity',
    CSP_SCORING = 'CspScoring',
    DEFAULT = 'Default',
    JUNIPER_AUDIT = "Juniper",
    AI_HIDE_SHOW_DYNAMIC = "AI_HIDE_SHOW_DYNAMIC"
}
